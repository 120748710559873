import { useState, useEffect } from "react";

export function FAQslider() {
    useEffect(() => {
        window.addEventListener('scroll', () => {
          var pill = document.getElementById("pill")
          var scrollPercent = getScrollPercent()  
          //console.log(scrollPercent)  
          pill.style.marginTop = getScrollPercent() + 'px'
      })
      }, []);
}

function getScrollPercent() {
    var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

      var pill = document.getElementById("pill"),
        stripe = document.getElementById("faq_stripe"),
        ph = 'offsetHeight',
        pt = 'offsetTop',
        pmt = pill.style.marginTop.replace("px", ""),
        originalpospill = (pill[pt] - pmt),
        // scrollPercentStripe = (((pill[pt] - originalpospill) / (stripe[ph] + stripe[pt])) * 100),
        // scrollPercentScreen = ((h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100),
        currentPixel = h[st],
        marginDifference = originalpospill - stripe[pt];
        if (currentPixel < stripe[pt]) 
        {
          return 0;
        }
        else if (currentPixel >= stripe[pt] && currentPixel < (stripe[ph] + stripe[pt]) - (pill[ph] + marginDifference))
        {
          return currentPixel - stripe[pt];
        }
        else 
        {
          return (stripe[ph] + stripe[pt]) - (pill[ph] + marginDifference) - stripe[pt];
        }
        
        
        // console.log((pill[pt] - originalpospill), stripe[ph] + stripe[pt])
        // console.log(((pill[pt] - originalpospill) / (stripe[ph] + stripe[pt])) * 100)
        // if (h[st] >= stripe[pt] && h[st] < stripe[ph] + stripe[pt]) {
          
        // }
    //return scrollPercent
}

export default getScrollPercent;
