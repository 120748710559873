import './App.css';
import Landing from './components/Landing/Landing';
import { useEffect } from 'react';

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

function App() {
  
  return (
    <Landing/>
  );
}

export default App;
