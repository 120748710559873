import React, { useEffect, useState } from "react";
import { ReactComponent as MainLogo } from './/svgs/mainlogo.svg'
import { ReactComponent as PresentedBy } from './/svgs/presentedby.svg'
import { ReactComponent as MainLogoFooter} from './/svgs/mainlogo_footer.svg'
import { ReactComponent as Discord} from './/svgs/discord.svg'
import { ReactComponent as Insta} from './/svgs/insta.svg'
import { ReactComponent as Email} from './/svgs/email.svg'
import Chart from './/svgs/chart.svg'
import Aeroseal from './/svgs/aeroseal.svg'
import "./Landing.css"

import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

import getScrollPercent, {FAQslider} from "./FAQslider";
import {ReactComponent as RegisterButton} from './/svgs/registerbutton.svg';

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

const Tab = styled(TabUnstyled)`
  font-family: 'Inter';
  color: #fff;
  cursor: pointer;
  font-size: calc(15px + 1vw);
  background-color: transparent;
  width: 100%;
  height: 80%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 10vh;
  display: flex;
  justify-content: center;
  text-shadow: 1px 1px 2px black;
  &:hover {
    background-color: rgb(99, 223, 247, .15);
  }
  &:focus {
    color: #75E9FF;
  }
  &.${tabUnstyledClasses.selected} {
    background-color: rgb(99, 223, 247, .38)
  }
`;

const TabPanel = styled(TabPanelUnstyled)(
  ({ theme }) => `
  width: 100%;
  font-size: 2rem;
  border-radius: 12px;
  opacity: 0.6;
  margin: 10px;
  color: white;
  `,
);

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 200px;
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `,
);


const Landing = () => {
  FAQslider();
  return (
    <div className="wrapper"> 
    <div className = "containerr">
      <div className = "stripe1"> 
        <MainLogo id = "mainlogo"/>
        <div className = "shape1" id="shape1"> 
        <PresentedBy id = "presented"/>
        <div className="register_container">
          <a className="reg_button" href="https://forms.monday.com/forms/950bcf6ea2fde13c0fb3867d03f3e10b?r=use1" style={{ textDecoration: 'none' }}>
            Register Now!
          </a>
        </div>
        </div>
      </div> 

      <div className = "stripe2"> 
        <div className = "stripe2_line1"> </div>
        
        <div className = "stripe2_content">
            <h2> what is illinois design challenge? </h2>  
            <div className = "stripe2_line2"> </div> 
        </div>
        <div className = "info1"> <p id = "info1">IDC is the first (and only!) 72-hour engineering and product design hackathon of the Midwest. Participants will design products and solutions to real-world problems that use engineering skills with human-centered design concepts, all while learning from industry experts in design and engineering. Register now for a chance to win from our $2000 prize pool!</p></div>
      </div>
      <div className = "event">
        <TabsUnstyled defaultValue={0}>
          <div className = "event1">
            <TabsList>
              <Tab>Day 1</Tab>
              <Tab>Day 2</Tab>
              <Tab>Day 3</Tab>
              <Tab>Day 4</Tab>
            </TabsList>
          </div>
      
          <div className = "event2">
            <TabPanel value = {0}>Coming Soon!</TabPanel>
            <TabPanel value = {1}>Coming Soon!</TabPanel>
            <TabPanel value = {2}>Coming Soon!</TabPanel>
            <TabPanel value = {3}>Coming Soon!</TabPanel>
          </div>
        </TabsUnstyled>
      </div>
      <div className = "stripe3"> 
        <div className = "stripe3_content"> <b>sponsors</b> </div> 
      </div>
      
      <div className = "sponsers"> 
        <h3> featured </h3> 
        <div className = "sponser_info">
          <img src={Chart} className = "sponser_img"/>
          <div className = "sponser_text"><b> Chart Industries </b> <p> Chart Industries is a leading manufacturing company in the industry of sustainable energy. They provide many engineering solutions in the industrial gas market along with upfront service and repair for the liquid gas supply chain. They emphasize diversity and inclusion among their team members and understand the importance of having different perspectives and cultures. To find out more about Chart Industries and their recent acquisition of Howden, visit:  chartindustries.com  </p></div>
        </div>
        <div className = "sponser_info">
          <img src={Aeroseal} className = "sponser_img"/>
          <div className = "sponser_text"><b> Aeroseal LLC </b> <p> Aeroseal is an industry-leading HVAC Sealing company that is based in Miamisburg, Ohio and is passionate about boosting people’s everyday lives by saving energy, improving air quality, and delivering comfort. </p></div>
        </div>
      </div>

      <div className = "stripe4"> </div>
      <div className = "stripe4_container">
        <div className = "stripe4_content"> Submission <b> Details </b> </div>
        <div className = "stripe4_details"> <p>All CAD files will be submitted through GrabCad</p><p>Porfolios will be submitted through DevPost</p></div> 
      </div>

      <div className = "faq">
        <div className = "faq_text">
          <div className = "faq_block">
            <div className = "q"> What incentives are there for participants? </div>
            Participants will receive free shirts during the event for their participation, and we will be giving away cash prizes worth $2000: $400 for first place per design challenge, $200 for second place per design challenge, and gift cards for the top percentage of workshop attendees!
          </div>
          <div className = "faq_block">
            <div className = "q"> How much experience do I need to compete? </div>
            No experience necessary! There will be workshops for participants covering topics including how to identify a design opportunity, basic CAD, submission portfolio details, and more to help all participants tackle our design challenges!
          </div>
          <div className = "faq_block">
            <div className = "q"> Which software can we use? </div>
            You are free to use any software you like. Some free softwares include Onshape and Fusion360. EWS machines at Grainger Engineering Library and Mechanical Engineering Laboratory offer additional softwares at no cost (we do not provide exclusive access to these labs, but can help you get set up!)!
          </div>
          <div className = "faq_block">
            <div className = "q"> Could we know more about the format of this competition? </div>
            This event will be a 68-hour engineering and product design sprint challenge (similar to a hackathon). You will get 68-hours to work on a judged project that solves a real-world need within a chosen challenge. We will have several workshops to guide you on your project, and daily help from IDC staff and mentors on Discord. At the end of the event, all teams will present their work to judges, and we will have an optional project showcase where each team may briefly present their work to the rest of the participants!          </div>
          <div className = "faq_block">
            <div className = "q"> What are the design challenges for this year? </div>
            We will be releasing our challenges at the opening ceremony! This year, we will have 3 challenges: 1 curated, 1 sponsor-led, and 1 open challenge. Open challenge projects must have been worked on during the 68 hours of the submission period.          </div>
          <div className = "faq_block">
            <div className = "q"> Can I submit multiple projects? </div>
            Each team may only submit 1 project per design challenge, and each participant may only be part of 1 team. However, a team may submit for as many design challenge categories as they wish!          </div>   
          <div className = "faq_block">
            <div className = "q"> Can you work alone or with a team? </div>
            It is up to you! You can work individually or in groups of up to 4. We will also help team match for those without teammates that want to work in a team.
          </div>
          <div className = "faq_block">
            <div className = "q"> Where do I find the schedule for the event? </div>
            Refer to the schedule on our website (will be posted the week of the event). The opening ceremony will be on Thursday at 6pm, project submissions will be due on Sunday 12pm followed by judging sessions, and the closing ceremony/awards will be on Sunday 6pm.
          </div> 
          <div className = "faq_block">
            <div className = "q"> Can you work alone or with a team? </div>
            It is up to you! You can work individually or in groups of up to 4. We will provide a platform for individuals to find teams if they choose to do so.
          </div> 
          <div className = "faq_block">
            <div className = "q"> Do we need to be in-person or can we compete virtually? </div>
            All participants are encouraged to come to CIF to engage with our amazing workshops, sponsors, and IDC staff. However, we only require that participants attend judging sessions and awards in person. Some workshops will be streamed and recorded via Zoom and Discord moderation will provide quick feedback on any questions at any time of the day. IDC Staff will also be hosting in-person office hours at certain times throughout the event
          </div>  
          <div className = "faq_block">
            <div className = "q"> Will there be food? </div>
            Yes! Dinner or Lunch will be served most days of the event, and we will have snacks and water available at all times to participants. And yes, it will be free!
          </div>   
        </div>
        <div className = "faq_stripe" id="faq_stripe">
            <div className= "faq_pill" id="pill" style={{marginTop : '0px'}}>
              <div style={{marginRight : '1.5vw'}}> F A Q </div>
            </div>
        </div>
      </div>
      <div className = "footer">
        <div className = "footer_content">
          <MainLogoFooter id = "mainlogo_footer"/>

          <Discord className = "socials"/>
          <a href = "https://www.instagram.com/illinoisdesignchallenge/"><Insta className = "socials"/></a>
          <a  href="mailto:illinoisdesignchallenge@gmail.com"><Email className = "socials"/></a>
        </div>
      </div>
    </div>
    </div>
  )
}



export default Landing